<template>
  <div class="figures-container" aria-hidden="true">
    <img src="/images/character-stills_00.png" alt="" />
    <img src="/images/character-stills_03.png" alt="" />
  </div>
</template>

<style scoped>
.figures-container {
  position: relative;

  img {
    position: absolute;
    top: 0;

    &:nth-child(2) {
      left: 6rem;
      top: 3rem;
    }
  }
}
</style>
