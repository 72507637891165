<script setup lang="ts">
import { KsButton } from "@aschehoug/kloss";

function handleCta() {
  navigateTo("/nyhetsbrev");
}
</script>

<template>
  <section class="newsletter-cta">
    <h3 class="newsletter-cta__title headline">
      Hold deg oppdatert
    </h3>
    <p class="newsletter-cta__intro">
      Med nyhetsbrev fra Aschehoug får du tips til undervisningen, inspirerende
      artikler, og invitasjoner til arrangementer.
    </p>
    <KsButton
      class="newsletter-cta__button"
      variant="primary"
      shape="rounded"
      @click="handleCta"
    >
      Meld deg på
    </KsButton>
    <div class="newsletter-cta__footer">
      <UiGraphicsFigureElementGroupA class="newsletter-cta__footer-figure1" />
      <UiGraphicsFigureElementAloneA class="newsletter-cta__footer-figure2" />
      <UiGraphicsFigureElementGroupB class="newsletter-cta__footer-figure3" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.newsletter-cta {
  width: 100vw;
  background-color: theme("colors.green.20");
  min-height: 20rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding-top: 2rem;

  .newsletter-cta__intro {
    color: theme("colors.green.50");
    max-width: pixelsToRem(560);
    margin: 0 1rem 2rem 1rem;
  }

  .newsletter-cta__button {
    z-index: 1;
    background: #fffae9;

    &:hover {
      background: theme("colors.yellow.30");
    }
  }

  .newsletter-cta__title {
    color: theme("colors.green.50");
    margin: 3rem 0 0.5rem 0;
    font-size: pixelsToRem(20);
    font-weight: 500;
    letter-spacing: 0.1rem;
  }

  .newsletter-cta__footer {
    width: 100%;
    min-height: 6rem;
    background-color: theme("colors.green.50");
    margin: 8rem 0 0 0;
    position: relative;

    @media screen and (min-width: $tablet) {
      margin: 12rem 0 0 0;
    }

    :deep(img) {
      max-width: unset;
    }

    .newsletter-cta__footer-figure1 {
      position: absolute;
      top: -7rem;
      left: 10%;
      transform: scale(0.6);

      @media screen and (min-width: $tablet) {
        transform: scale(1);
        left: 20%;
        top: -13rem;
      }
    }

    .newsletter-cta__footer-figure2 {
      position: absolute;
      top: -7rem;
      left: 30%;
      transform: scale(0.6);

      @media screen and (min-width: $small) {
        left: 30%;
        transform: scale(1);
        top: -12rem;
      }

      @media screen and (min-width: $tablet) {
        left: 40%;
        transform: scale(1);
      }
    }

    .newsletter-cta__footer-figure3 {
      position: absolute;
      top: -7rem;
      left: 50%;
      transform: scale(0.6);

      @media screen and (min-width: $tablet) {
        transform: scale(1);
        left: 55%;
        top: -13rem;
      }
    }
  }
}
</style>
